import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import "../../components/MainPage/ImgSlider.css";
import MSI0 from "../../assets/imgs/main slider/vid.mp4";
import MSI1 from "../../assets/imgs/main slider/msi1.jpg";
import MSI2 from "../../assets/imgs/main slider/msi2.jpg";
import MSI3 from "../../assets/imgs/main slider/msi3.jpg";
import MSI4 from "../../assets/imgs/main slider/msi4.jpg";
import MSI5 from "../../assets/imgs/main slider/msi5.jpg";
import MSI6 from "../../assets/imgs/main slider/msi6.jpg";
import MSI7 from "../../assets/imgs/main slider/msi7.jpg";
import MSI8 from "../../assets/imgs/main slider/msi8.jpg";
import MSI9 from "../../assets/imgs/main slider/msi9.jpg";
import MSI10 from "../../assets/imgs/main slider/msi10.jpg";
import MSI11 from "../../assets/imgs/main slider/msi11.jpg";
import MSI12 from "../../assets/imgs/main slider/msi12.jpg";
import MSI13 from "../../assets/imgs/main slider/msi13.jpg";
import MSI14 from "../../assets/imgs/main slider/msi14.jpg";
const MyCarousel = () => {
  return (
    <Carousel fade interval={2000}>
      <Carousel.Item interval={9000}>
          <div className="carousel-vid" style={{backgroundImage:`url(${MSI0})`}}></div>
          <div className="carousel-nonvid" style={{backgroundImage:`url(${MSI3})`}}></div>
        </Carousel.Item>
       <Carousel.Item style={{backgroundImage:`url(${MSI9})`}}></Carousel.Item>
       <Carousel.Item style={{backgroundImage:`url(${MSI4})`}}></Carousel.Item> 
        <Carousel.Item style={{backgroundImage:`url(${MSI2})`}}></Carousel.Item>
        <Carousel.Item style={{backgroundImage:`url(${MSI11})`}}></Carousel.Item>
        <Carousel.Item style={{backgroundImage:`url(${MSI5})`}}></Carousel.Item>
        <Carousel.Item style={{backgroundImage:`url(${MSI1})`}}></Carousel.Item>
        <Carousel.Item style={{backgroundImage:`url(${MSI6})`}}></Carousel.Item>
        <Carousel.Item style={{backgroundImage:`url(${MSI7})`}}></Carousel.Item>
        <Carousel.Item style={{backgroundImage:`url(${MSI8})`}}></Carousel.Item>
        <Carousel.Item style={{backgroundImage:`url(${MSI12})`}}></Carousel.Item>
        <Carousel.Item style={{backgroundImage:`url(${MSI10})`}}></Carousel.Item>
        <Carousel.Item style={{backgroundImage:`url(${MSI13})`}}></Carousel.Item>
        <Carousel.Item style={{backgroundImage:`url(${MSI14})`}}></Carousel.Item>
      </Carousel>
    );
  }
  
export default MyCarousel