import React from 'react';
import {
  MDBFooter,
  MDBContainer,
} from 'mdb-react-ui-kit';
import instagram from '../../assets/icons/instagram1.png';
import whatsapp from '../../assets/icons/whatsapp1.png';
import linkedin from '../../assets/icons/linkedin.png';
import phone from '../../assets/icons/phone1.png';
import "../../components/UI/Footer.css";
import nayanlogo from '../../assets/logos/nayanlogo.png';
export default function Footer() {
    return (
    <div className="footer-color">
    <MDBFooter className='footer-bg text-center text-white'>
      <MDBContainer className='p-4 pb-0'>
              <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
              <img
              className="nayanlogo-footer"
              src={nayanlogo}
              alt="Nayan Logo"
            ></img>
              </div>
              <ul className="list-inline">
              <li className="list-inline-item">

              <a className='btn btn-footer m-1' target='_blank' href="tel:+966561291512" role='button'>
              <img
              className="social-footer"
              src={phone}
              alt=""
              width="30"
              height="30"
                ></img>
              </a>
              </li>
              <li className="list-inline-item">

            <a className='btn btn-footer m-1' target='_blank' href='https://www.linkedin.com/company/mrac-nayan/' role='button'>
            <img
                className="social-footer"
                src={linkedin}
                alt=""
                width="30"
                height="30"
                  ></img>
                </a>
                </li>

                <li className="list-inline-item">

            <a className='btn btn-footer m-1' href='#!' role='button'>
            <img
                className="social-footer"
                src={instagram}
                alt=""
                width="30"
                height="30"
                  ></img>
            </a>
                </li>

                <li className="list-inline-item">
                <a className='btn btn-footer m-1' target='_blank' href='https://api.whatsapp.com/send/?phone=966561291512&text&type=phone_number&app_absent=0' role='button'>
          <img
              className="social-footer"
              src={whatsapp}
              alt="Whicon"
              width="30"
              height="30"
            ></img> 
          </a>
                </li>
              </ul>
      </MDBContainer>

      <div className='address text-center p-3'>
        <p className='text-white small' >
        <a target="_blank" className="text-decoration-none text-reset" href="https://eazywd.com">eazy WEB DESIGN</a> جميع الحقوق محفوظة © لشركة محمد راشد أبو نيان للتجارة والاستثمار - حي الملقا - الرياض - تطوير
        </p>
        
      </div>
    </MDBFooter>
 </div>
  );
}