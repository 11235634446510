import React, { Component } from "react";
import Slider from "react-slick";
import "../MainPage/Partners.css";
import Plogo1 from "../../assets/logos/PartnersLogos/Plogo-01.png";
import Plogo2 from "../../assets/logos/PartnersLogos/Plogo-02.png";
import Plogo3 from "../../assets/logos/PartnersLogos/Plogo-03.png";
import Plogo4 from "../../assets/logos/PartnersLogos/Plogo-04.png";
import Plogo5 from "../../assets/logos/PartnersLogos/Plogo-05.png";
import Plogo6 from "../../assets/logos/PartnersLogos/Plogo-06.png";
import Plogo7 from "../../assets/logos/PartnersLogos/Plogo-07.png";
import Plogo8 from "../../assets/logos/PartnersLogos/Plogo-08.png";
import Plogo9 from "../../assets/logos/PartnersLogos/Plogo-09.png";

/*IMPORTANT */
//https://react-slick.neostack.com/docs/example/responsive
//https://our-partners.netlify.app/
export default class Partners extends Component {
    render() {
        var settings = {
            slidesToShow: 5,
            slidesToScroll: 5,
            autoplay: true,
            autoplaySpeed: 4000,
            speed: 1000,
            pauseOnHover: true,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                }
            ]
        };
        return (
            <section id="Partners">
                <div className="container">
                    <div className="row justify-content-md-center pt-3">
                        <div className="container text-center">
                            <div className="content-section-heading">
                                <h1>شركائنا</h1>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-md-center carousel-client">
                        <Slider {...settings}>
                            <div>
                                <img
                                    className="partner-logo"
                                    alt="" src={Plogo1}
                                ></img>
                            </div>
                            <div>
                                <img
                                    className="partner-logo"
                                    alt="" src={Plogo2}
                                ></img>
                            </div>
                            <div>
                                <img
                                    className="partner-logo"
                                    alt="" src={Plogo4}
                                ></img>
                            </div>
                            <div>
                                <img
                                    className="partner-logo"
                                    alt="" src={Plogo5}
                                ></img>
                            </div>
                            <div>
                                <img
                                    className="partner-logo"
                                    alt="" src={Plogo6}
                                ></img>
                            </div>
                            <div>
                                <img
                                    className="partner-logo"
                                    alt="" src={Plogo7}
                                ></img>
                            </div>
                            <div>
                                <img
                                    className="partner-logo"
                                    alt="" src={Plogo8}
                                ></img>
                            </div>
                            <div>
                                <img
                                    className="partner-logo"
                                    alt="" src={Plogo9}
                                ></img>
                            </div>
                        </Slider>
                    </div>
                </div>
            </section>
        );
    }
}

//https://www.npmjs.com/package/react-responsive-carousel
//https://codesandbox.io/s/react-responsive-carousel-forked-6ncggt?file=/src/index.js
