import React from "react";
import "../UI/LoadingPage.css";
import nayanlogo from "../../assets/logos/nayanlogo.svg";
import BarLoader from "react-spinners/BarLoader";

const LoadingPage = () => {  
  return (
      <div className="pb-5 mb-5 col-0 justify-content-md-center">
          <div className="row Centerr">
              <img alt="" className='loader-img' src={nayanlogo} />
          </div>
          <div className="row Centerr">
          <div className="stw">نهتم بتفاصيل مسكنك</div>
              <BarLoader color={"#fff"} size={350}  />
          </div>
    </div>
  )
}
export default LoadingPage;