import { MarkerClusterer } from "@googlemaps/markerclusterer";
import av from "../../../assets/icons/MapsMarkers/marker-av.png";
import so from "../../../assets/icons/MapsMarkers/marker-so.png";
import sd from "../../../assets/icons/MapsMarkers/marker-sd.png";
import cluster from "../../../assets/icons/MapsMarkers/cluster.png";

let infoWindow;
let infoStorage;
let google;
let map;
const locns = [
  [{ lat: 24.795979, lng: 46.613452 }, sd, "نيان 2909", "/2909"],
  [{ lat: 24.822497, lng: 46.626808 }, sd, "نيان 3299", "/3299"],
  [{ lat: 24.798978, lng: 46.622226 }, av, "نيان 3794", "/3794"],
  [{ lat: 24.820428, lng: 46.585594 }, sd, "نيان 3569", "/3569"],
  [{ lat: 24.791495, lng: 46.622418 }, sd, "نيان 3832", "/3832"],
  [{ lat: 24.838669, lng: 46.754349 }, sd, "نيان 7580", "/7580"],
  [{ lat: 24.812991, lng: 46.612000 }, so, "نيان 7119", "/7119"],
  [{ lat: 24.812055, lng: 46.612000 }, so, "نيان 7110", "/7110"],
];

const MyMapComponent = () => {
   google = window.google;

   map = new google.maps.Map(document.getElementById("map"), {
    mapId: "4018a4978c2d3b34",
    center: { lat: 24.788277, lng: 46.679914 },
    zoom: 11
  });

  // Add some markers to the map.
  // Note: The code uses the JavaScript Array.prototype.map() method to
  // create an array of markers based on a given "locations" array.
  // The map() method here has nothing to do with the Google Maps API.
  const markers = locns.map((position, i) => {
    const marker = new google.maps.Marker({
      position: locns[i][0],
      icon: {url:locns[i][1], scaledSize: new google.maps.Size(39, 39)},
      name: locns[i][2],
      link: locns[i][3]
    });

    // markers can only be keyboard focusable when they have click listeners
    // open info window when marker is clicked
    marker.addListener("click", () => {
      map.panTo(marker.getPosition());
      if (infoWindow) {
        infoWindow.close();
      }
      createInfoWindow(marker);
    });
    return marker;
  });
  const renderer = {
    render: ({ count, position }) =>
      new google.maps.Marker({
        label: { text: String(count), color: "white", fontSize: "24px", fontWeight:"bold" },
        position,
        icon: {url:cluster, scaledSize: new google.maps.Size(50, 50)},
        zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
      }),
  };
  // Add a marker clusterer to manage the markers.
  new MarkerClusterer({ markers, map, renderer });

  const legend = document.getElementById("legend");
  map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(legend);

  return null;
};

function createInfoWindow(marker) {
  // Build the content of the InfoWindow
  const contentDiv = document.createElement("div");
  const nameDiv = document.createElement("a");
  contentDiv.classList.add("infowindow-content");
  nameDiv.classList.add("title");
  nameDiv.setAttribute("href", marker.link);
  nameDiv.textContent = marker.name;
  contentDiv.appendChild(nameDiv);
  // Create and open a new InfoWindow
  infoWindow = new google.maps.InfoWindow();
  infoWindow.setContent(contentDiv);
  infoWindow.open(map, marker);
  // Store key properties of the InfoWindow for future restoration
  infoStorage = {
    marker: marker,
  };
  // Clear content storage if infoWindow is closed by the user
  infoWindow.addListener("closeclick", () => {
    if (infoStorage) {
      infoStorage = null;
    }
  });
}

export default MyMapComponent;
