import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import graybg from "../../assets/misc/graybg.svg";
import "../MainPage/AboutUs.css";

function FadeInSection(props) {
    const [isVisible, setVisible] = React.useState(false);
    const domRef = React.useRef();
    React.useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => setVisible(entry.isIntersecting));
        });
        observer.observe(domRef.current);
    }, []);
    return (
        <div
            className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
            ref={domRef}
        >
            {props.children}
        </div>
    );
}

const name = () =>  (
    <section style={{ backgroundImage: `url(${graybg})` }} className="graybg" id="AboutUs">
        <div className="container">
            <div className="row justify-content-md-center pt-3">
                <div className="container text-center">
                    <div className="content-section-heading">
                        <h1>عن نيان</h1>
                    </div>
                </div>
            </div>
            <div className="row justify-content-md-center">
                <Container>
                    <Row>
                        <Col s="12" lg="4">
                            <FadeInSection>
                                <div className="box">
                                    <h3 className="box-f">من نحن؟</h3>
                                    <span className="box-f">منذ عام 2005 انطلقت  شركة محمد راشد ابونيان للتجارة والاستثمار (نيان) حاملة على عاتقها الإهتمام بالتفاصيل في إنشاء عقارات ذات معايير سامية الجودة في قلب الرياض. اخذ فريقنا ميثاق بأن نصنع تحفة فنية تلائم ذوق المجتمع السعودي الفاخر و التي من خلالها تمزج بين متطلبات المجتمع والتصميم الحديث. مُصنفه نيان لدى الهيئة العامة للعقار برقم نيان 192. </span>
                                </div>
                            </FadeInSection>
                        </Col>

                        <Col s="12" lg="4">
                            <FadeInSection>
                                <div className="box">
                                    <h3 className="box-f">رسالتنا</h3>
                                    <span className="box-f">نتطلع ان تكون نيان رائدة في صناعة العقارات بحيث تتوافق مشاريعنا مع توقعات واحتياجات المجتمع السعودي وفق معايير ومقايس عالية في عاصمتنا الحبيبة.</span>
                                </div>
                            </FadeInSection>
                        </Col>

                        <Col s="12" lg="4">
                            <FadeInSection>
                                <div className="box">
                                    <h3 className="box-f">خدماتنا</h3>
                                    <span className="box-f">نقدم لكم في نيان خدمة إدارة وتطوير العقارات بكافة أنواع البناء سواءً في المشاريع العقارية او التجارية بكل دقة واهتمام والتركيز على الكفاءة والجودة من واقع خبرتنا في المجال العقاري. بالإضافة إلى تسهيل جميع الخدمات للعميل ومنحه جميع الخيارات الخاصة والمناسبة وفق متطلبات العميل.</span>
                                </div>
                            </FadeInSection>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    </section>
);

export default name;

//https://www.npmjs.com/package/react-responsive-carousel
//https://codesandbox.io/s/react-responsive-carousel-forked-6ncggt?file=/src/index.js