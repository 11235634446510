import React, { Component, useState, useRef } from "react";
import emailjs from '@emailjs/browser';
import Countdown from 'react-countdown';
import Button from 'react-bootstrap/Button';
import "./Apartments.css";
import FlipCard from 'react-flipcard-2';
import unit from "../../assets/icons/nayan icon without text/NIWT-unit.png";
import area from "../../assets/icons/nayan icon without text/NIWT-area.png";
import info from "../../assets/icons/nayan icon without text/NIWT-info.png";
import location from "../../assets/icons/nayan icon without text/NIWT-location.png";
import privacy from "../../assets/icons/nayan icon without text/NIWT-privacy.png";
import duplex from "../../assets/icons/duplex.png";
import map from "../../assets/icons/map.png";
import money from "../../assets/icons/money.png";
import Slider from "react-slick";
import Modal from 'react-bootstrap/Modal';
import { Container, Col, Row } from "react-bootstrap";

const IntrestCardUi = props => {
  const form = useRef(null);
  const [statusMessage, setStatusMessage] = useState("");
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_w1ypnpf', 'template_fpb98h9', form.current, 'GpjnKM2NC54EdunnF')
      .then((result) => {
        console.log(result.text);
        setStatusMessage( alert("تم ارسال ردك بنجاح .. سنتواصل معك في اقرب وقت ممكن"));
      }, (error) => {
        console.log(error.text);
        setStatusMessage( alert("حدث خطأ في الارسال .. حاول مرة اخرى"));
      });
    e.target.reset();
  };
  return (
    <FlipCard
      disabled={true}>
      <Container>
        <Row className="row-border mb-4">
          <Col ><div className="big-title">سجّل اهتمامك</div>
            <div className="st">قم من فضلك بتعبئة التفاصيل التالية</div></Col>
        </Row>
        <form ref={form} onSubmit={sendEmail}>
          <p className="message-status">{statusMessage}</p>
          <Row className="row-border">
            <div className="col-lg-6 col-sm-12"><input type="text" className="form-control line" id="name" name="name" placeholder="الاسم*" required /></div>
            <div className="col-lg-6 col-sm-12"><input type="text" className="form-control line" id="mobile" name="mobile" placeholder="رقم الجوال (05xxxxxxxx)*" required maxLength="10" min="9" onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault(); }}}/></div>
            <div className="col-lg-6 col-sm-12"><input type="hidden" id="Pname" name="Pname" value={props.name1}/></div>
          </Row>
          <Row className="row-border">
            <div className="col-lg-6 col-sm-12">
              <select id="interest" name="interest" className="form-control line">
                <option value="">طريقة الشراء</option>
                <option value="نقد">نقد</option>
                <option value="تمويل اسكان">تمويل بنكي</option>
              </select>
            </div>
            <div className="col-lg-6 col-sm-12"><input type="text" className="form-control line" id="details" name="details" placeholder="مواصفات الشقة" required /></div>
          </Row>
          <Row className="row-border row justify-content-md-center center-1">
            <button className="new-Btn-2" type="submit" value="Send" variant="dark" >إرسال</button>
          </Row>
        </form>
      </Container>
      <div>
        <p className="message-status">{statusMessage}</p>
      </div>
    </FlipCard>
  )
}
const slides = (count, num) => Array(count).fill().map((item, i) => (
  <img className="o-fit" key={i} alt="" src={require(`../../assets/imgs/ProjectsPhotos/Nayan ` + num + `/` + num + `-${i + 1}.jpg`)} />
));

const FlipCardUi = props => {
  return (
    <FlipCard>
      <div>
        <Container>
          <Col className="Col-border col">
            <Row className="row-border"><img className="icon-feature-2 icon-card" alt="" src={props.mainicon}></img>
            </Row>
            <Row className="row-border">
              <div className="feature-title">{props.mainfeature}</div>
              <div className="row"><span className="press">اضغط للمزيد</span></div>
            </Row>
          </Col>
        </Container>
      </div>
      <div>
        <div className="container-sm container-1">
          <div className="row row-featuresz">
            <div className="col-4">
              <div className="row">
                <img className="icon-feature-1" alt="" src={props.icon1}></img>
              </div>
              <div className="row"><span>{props.feature1}</span></div>
            </div>
            <div className="col-4 disappear">
              <div className="row">
                <img className="icon-feature-1" alt="" src={props.icon2}></img>
              </div>
              <div className="row"><span>{props.feature2}</span></div>
            </div>
            <div className="col-4">
              <div className="row">
                <img className="icon-feature-1" alt="" src={props.icon3}></img>
              </div>
              <div className="row"><span>{props.feature3}</span></div>
            </div>
          </div>
        </div>
      </div>
    </FlipCard>
  )
}
const PriceList = props => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = props.link_pl === "#" ? () => setShow(true): "";
  return (
    <a target={props.link_tar} onClick={props.link_oc} href={props.link_pl} className="new-btn-3">
        <button className="new-btn-1 prices pb-1" onClick={handleShow} variant="dark">   قائمة الاسعار    <img src={money} className="btn-icon" alt="" /></button>
        <Modal
        size="md"
        show={show}
        onHide={handleClose}
        aria-labelledby="example-modal-sizes-title-lg"
        >  
        <Modal.Header closeButton>
        <Modal.Title className="box-f">قائمة الاسعار</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <h3 className="box-f">قائمة الاسعار غير متوفرة حالياً.</h3>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="dark" className="cardBtn" onClick={handleClose}>
        اغلاق
        </Button>
        </Modal.Footer>
      </Modal>
    </a>
  )
}

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  // Render a countdown
  return (
    <table className="countdown-table">
      <tbody>
      <tr>
        <td>{seconds}</td>
        <td>{minutes}</td>
        <td>{hours}</td>
        <td>{days}</td>
      </tr>
      <tr>
        <td>ثانية</td>
        <td>دقيقة</td>
        <td>ساعة</td>
        <td>يوم</td>
      </tr>
      </tbody>
    </table>);
};
export default class Apartments extends Component {
  
  getState() {
    return {
      isFlipped: false
    };
  }
  showBack() {
    this.setState({
      isFlipped: true
    });
  }
  showFront() {
    this.setState({
      isFlipped: false
    });
  }
  handleOnFlip(flipped) {
    if (flipped) {
      this.refs.backButton.focus();
    }
  }
  handleKeyDown(e) {
    if (this.state.isFlipped && e.keyCode === 27) {
      this.showFront();
    }
  }

  render() {
    var settings = {
      className: "center",
      centerMode: true,
      centerPadding: "40px",
      dots: true,
      dotsClass: "slick-dots slick-thumb",
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
            className: "center",
            centerMode: true,
            centerPadding: "45px",
            slidesToScroll: 1,
            arrows: false,
            focusOnSelect: true
          }
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 1,
            className: "center",
            centerMode: true,
            centerPadding: "65px",
            slidesToScroll: 1,
            arrows: false,
            focusOnSelect: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            className: "center",
            centerMode: true,
            centerPadding: "25px",
            slidesToScroll: 1,
            arrows: false,
            focusOnSelect: true
          }
        },
        {
          breakpoint: 448,
          settings: {
            slidesToShow: 1,
            className: "center",
            centerMode: true,
            centerPadding: "0px",
            slidesToScroll: 1,
            arrows: false,
            focusOnSelect: true
          }
        },
        {
          breakpoint: 400,
          settings: {
            slidesToShow: 1,
            className: "center",
            centerMode: true,
            centerPadding: "0px",
            slidesToScroll: 1,
            arrows: false,
            focusOnSelect: true
          }
        }
      ]
    };

    const isSoon = this.props.currentstatus==="soon";
    const isSold = this.props.currentstatus==="sold";
    const isAv = this.props.currentstatus==="available";
    const isRe = this.props.currentstatus==="availablerent";

    return (
      <section id="Apartments" className="header-content">
        <div className="container-fluid p-0 ltr">
          <div className={this.props.status}><span>
          {isSoon? (
                    <Countdown
                    date={this.props.date}
                    renderer={renderer}
                    />
                ) : (
                    null
                )}
                {isSold? (
                    <div>مباع</div>
                ) : (
                    null
                )}
                {isAv? (
                    <div>متاح</div>
                ) : (
                    null
                )}
                {isRe? (
                    <div>متاح للإيجار</div>
                ) : (
                    null
                )}
          </span>
          </div>
          <div className="row justify-content-md-center text-center rtl">
            <div className={this.props.class}>
              <h1 className="display-0 box-f">{this.props.name}</h1>
              <div className="row justify-content-md-center row-header pt-2 center-1">
                <a target="_blank" href={this.props.link_map} className="new-btn-3">
                <button className="new-btn-1 location pb-1" target="_blank"> اتجاهات الموقع <img src={map} className="btn-icon" alt="" /></button>
                </a>
                <PriceList link_pl = {this.props.pl}  link_oc = {this.props.oc} link_tar = {this.props.tar}> </PriceList>
              </div>
            </div>
          </div>
          <div className="row justify-content-md-center text-center middle-layer rtl">
            <div className="col-lg-6 col-md-12 col-sm-12 pl-0">
              <div className="container">
                <div className="row justify-content-md-center height2">
                  <div className="col-lg-6 col-md-6 col-sm-12 ">
                    <FlipCardUi
                      mainicon={info}
                      mainfeature="نوع المشروع"
                      icon1={this.props.type_icon}
                      feature1={this.props.type_name}
                      icon2={unit}
                      feature2={this.props.units_num}
                      icon3={area}
                      feature3={this.props.areas}>
                    </FlipCardUi>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 ">
                    <FlipCardUi
                      mainicon={location}
                      mainfeature="موقع مميز"
                      icon1={this.props.loc1_icon}
                      feature1={this.props.loc1_name}
                      icon2={this.props.loc2_icon}
                      feature2={this.props.loc2_name}
                      icon3={this.props.loc3_icon}
                      feature3={this.props.loc3_name}>
                    </FlipCardUi>
                  </div>
                </div>
                <div className="row justify-content-md-center height2">
                  <div className="col-lg-6 col-md-6 col-sm-12 ">
                    <FlipCardUi
                      mainicon={duplex}
                      mainfeature="مرافق متعددة"
                      icon1={this.props.am1_icon}
                      feature1={this.props.am1_name}
                      icon2={this.props.am2_icon}
                      feature2={this.props.am2_name}
                      icon3={this.props.am3_icon}
                      feature3={this.props.am3_name}>
                    </FlipCardUi>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <FlipCardUi
                      mainicon={privacy}
                      mainfeature="امان عالي"
                      icon1={this.props.sec1_icon}
                      feature1={this.props.sec1_name}
                      icon2={this.props.sec2_icon}
                      feature2={this.props.sec2_name}
                      icon3={this.props.sec3_icon}
                      feature3={this.props.sec3_name}>
                    </FlipCardUi>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 pr-0">
            <div className="container">

              <div className="row justify-content-md-center height1">
              <div className="col-lg-12 col-md-12 col-sm-12">

                <IntrestCardUi
                  name1={this.props.name}
                />
                </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-md-center text-center rtl">
            <div className="container">{this.props.greeting}
              <div className="row gal justify-content-md-center carousel-client">
                <Slider {...settings}>
                  {slides(parseInt(this.props.count), this.props.num)}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section >
    );
  }
}