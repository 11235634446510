import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import Mainpage from "./pages/Mainpage";
import N3794 from "./pages/3794";
import N3299 from "./pages/3299";
import N2909 from "./pages/2909";
import N7580 from "./pages/7580";
import N3832 from "./pages/3832";
import N3569 from "./pages/3569";
import N7119 from "./pages/7119";
import N7110 from "./pages/7110";
import N4845 from "./pages/4845";
import N3839 from "./pages/3839";
import N2880 from "./pages/2880";
import LoadingPage from './components/UI/LoadingPage';
function App() {
  const [loading, setloding] = useState(false);
  useEffect(() => {
    setloding(true) 
    setTimeout(() => {
      setloding(false)
    }, 2200);
  },[])
  return (
    <div className="App">
      {loading ?
         <div className='loader'>
          <LoadingPage loading={loading}/>
          </div>
        :
        <Routes>
          <Route path="/" exact element={<Mainpage/>}/>
          <Route  path="/3794" exact element={<N3794/>}/>
          <Route  path="/7580" exact element={<N7580/>}/>
          <Route  path="/2909" exact element={<N2909/>}/>
          <Route  path="/3299" exact element={<N3299/>}/>
          <Route  path="/3832" exact element={<N3832/>}/>
          <Route  path="/3569" exact element={<N3569/>}/>
          <Route  path="/7119" exact element={<N7119/>}/>
          <Route  path="/7110" exact element={<N7110/>}/>
          <Route  path="/4845" exact element={<N4845/>}/>
          <Route  path="/3839" exact element={<N3839/>}/>
          <Route  path="/2880" exact element={<N2880/>}/>
        </Routes>
        }
      
      </div>
  );
}
export default App;
