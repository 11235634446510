import React from 'react';
import { Fragment } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import MainNav from "../components/UI/MainNav"
import MyCarousel from "../components/MainPage/ImgSlider"
import AboutUs from "../components/MainPage/AboutUs"
import OurProducts from "../components/MainPage/OurProducts"
import News from "../components/MainPage/News"
import Partners from "../components/MainPage/Partners"
import Footer from '../components/UI/Footer';
import RentButton from '../components/MainPage/RentButton';
import ScrollToTop from '../components/ProductsPages/ScrollToTop.js';
function MainPage() {
  return (
    <Fragment>
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
        integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
        crossOrigin="anonymous" />
      <ScrollToTop />
      <MainNav />
      <MyCarousel />
      <RentButton />
      <AboutUs />
      <OurProducts /> 
      <News />
      <Partners />
      <Footer />
    </Fragment>
  );
}
export default MainPage;