import React from 'react';
import { Fragment } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import MainNav from '../components/UI/MainNav';
import Footer from '../components/UI/Footer';
import Apartments from '../components/ProductsPages/Apartments.js';
import ScrollToTop from '../components/ProductsPages/ScrollToTop.js';
import courtyard from "../assets/icons/nayan icon without text/NIWT-courtyard.png";
import elevator from "../assets/icons/nayan icon without text/NIWT-elevator.png";
import privatedoor from "../assets/icons/nayan icon without text/NIWT-privatedoor.png";
import roof from "../assets/icons/nayan icon without text/NIWT-roof.png";
import villas from "../assets/icons/nayan icon without text/NIWT-villas.png";
import camera from "../assets/icons/nayan icon without text/NIWT-camera.png";
import locker from "../assets/icons/nayan icon without text/NIWT-locker.png";
import airport from "../assets/icons/nayan icon without text/NIWT-airport.png";
import metro from "../assets/icons/nayan icon without text/NIWT-metro.png";
import road from "../assets/icons/nayan icon without text/NIWT-road.png";
function ProductPage() {
  return (
    <Fragment>
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
        integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
        crossOrigin=""/>
      <ScrollToTop />
      <MainNav />
      <Apartments
          num = "3569"
          count = "10"
          status="status text-center text-white sold"
          date = {new Date(2023, 1, 31, 0, 0, 0)}
          currentstatus="sold"
          link_map={"https://goo.gl/maps/19HWzBAqxoJraALm9"}
          name="نيان القيروان 3569"
          class="header-pic bg-3569"
          type_icon={villas} 
          type_name="وحدات تاون هاوس"
          units_num="٩ وحدة سكنية"
          areas="١٩٠-٣٥٠م"
          loc1_icon={road}
          loc1_name="قريب من طريق الملك فهد"
          loc2_icon={airport}
          loc2_name="الوصول للمطار 15 دقيقة"
          loc3_icon={metro}
          loc3_name="قريب من محطة الميترو"
          am1_icon={elevator}
          am1_name="مصعد"
          am2_icon={roof}
          am2_name="سطح"
          am3_icon={courtyard}
          am3_name="فناء"
          sec1_icon={locker}
          sec1_name="اقفال ذكية"
          sec2_icon={camera}
          sec2_name="كاميرات مراقبة"
          sec3_icon={privatedoor}
          sec3_name="مدخل خاص"
          pl = "#"
          tar = ""
          oc={(e) => {e.preventDefault()}}
          />
      <Footer />
    </Fragment>
  );
}
export default ProductPage;